
import { Component, Mixins, Prop } from "vue-property-decorator";
import FormValidator from "@/mixins/FormValidator";

@Component({
  components: {}
})
export default class FormEncuestaServicio extends Mixins(FormValidator) {
  private url_frontoffice = process.env.VUE_APP_FRONT_OFFICE_URL as string;
  private text = "";
  private submitResult: any;
  private idTicket: any;
  private token: any;
  private evaluaciones = {
    pregunta1: 0,
    pregunta2: 0,
    pregunta3: 0
  };

  private mounted() {
    if (this.$router.currentRoute.params.token) {
      this.token = this.$router.currentRoute.params.token;
      this.idTicket = this.$router.currentRoute.params.id_ticket;
    } else {
      window.open(this.url_frontoffice, "_self");
    }
  }

  protected onSubmit(evt: any) {
    const formData = new FormData(evt.target);
    const submitResult = [];

    for (const [name, value] of formData.entries()) {
      submitResult.push({
        name,
        value
      });
    }

    this.submitResult = submitResult;
    if (
      this.submitResult[0].value == 0 ||
      this.submitResult[1].value == 0 ||
      this.submitResult[2].value == 0
    ) {
      this.$q.notify({
        message: "Debe seleccionar una calificación por pregunta",
        color: "principal",
        position: "top",
        timeout: 5000
      });
      return;
    } else {
      this.$axios
        .post("/evaluacion-tickets/array", {
          id_ticket: parseInt(this.idTicket),
          respuesta_1: parseInt(this.submitResult[0].value),
          respuesta_2: parseInt(this.submitResult[1].value),
          respuesta_3: parseInt(this.submitResult[2].value),
          comentario: this.text,
          token: this.token
        })
        .then(response => {
          this.$q.notify({
            message: "Se evaluó el servicio correctamente",
            color: "principal",
            position: "top",
            timeout: 5000
          });
        })
        .catch(error => {
          if (error.response.data.error.message == "expired_token") {
            this.$q.notify({
              message:
                "La evaluación del servicio ya ha sido enviada previamente",
              color: "principal",
              position: "top",
              timeout: 5000
            });
          } else {
            this.$q.notify({
              message: "Ha ocurrido un error al evaluar el servicio",
              color: "principal",
              position: "top",
              timeout: 5000
            });
          }
        })
        .finally(() => {
          window.open(this.url_frontoffice, "_self");
        });
    }
  }
}
